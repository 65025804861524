.BranchCard{
    width: 330px;
    height: 190px;
    border-radius: 10px;
    background: #fff;
    box-shadow: rgba(0, 0, 0, 0.1) 0rem 0.25rem 0.375rem -0.0625rem, rgba(0, 0, 0, 0.06) 0rem 0.125rem 0.25rem -0.0625rem;
    overflow: hidden;
    cursor: pointer;
}
.BranchLogo{
    height: 100%;
    width: 100%;
    background-color: #fff;
    /* padding-top: 20px; */
    padding-bottom: 20px;
    padding-left: 20px;
    padding-right: 10px;
}
.statacticsDisplayBlock{
    width: 60%;
    padding-top: 20px;
    padding-bottom: 10px;
    padding-left: 10px;
    padding-right: 20px;
}
.consoleName {
    padding-top: 5px;
    height: 25%;
    font-size: 16px;
    width: 100%;
    font-weight: 600;
    letter-spacing: 1px;
    text-align: center;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.statacticsDisplayHeader{
    font-size: 16px;
    color: rgba(0, 0, 0, 0.5);
    text-align: end;
}

.statacticsDisplayDisplay{
    font-size: 24px;
    font-weight: 600;
    text-align: end;
}