.cardWrp {
    padding: 50px;
}

.addCard {
    margin-top: 20px;
    padding-top: 2px;
    margin-left: 30px;
    padding-left: 30px;
    padding-right: 30px;
    padding-bottom: 30px;
    border-radius: 10px;
    height: 300px;
    width: 100%;
    background-color: #fff;
    box-shadow: rgba(0, 0, 0, 0.1) 0rem 0.25rem 0.375rem -0.0625rem, rgba(0, 0, 0, 0.06) 0rem 0.125rem 0.25rem -0.0625rem;
}

.tableCardMargin {
    margin-left: 30px;
    margin-right: 30px;
}

.userTableContainer {
    /* margin-top: 100px; */
}