.countcardProduct{
    width: 100%;
    height: 80px;
    background-color: white;
    border-radius: 10px;
    box-shadow: rgba(195, 146, 146, 0.1) 0rem 0.25rem 0.375rem -0.0625rem, rgba(0, 0, 0, 0.06) 0rem 0.125rem 0.25rem -0.0625rem;
    padding-right: 20px;
    padding-left: 10px;
}

.logoContainer{
    padding-top: 12px;
    height: 60px;
    width: 60px;
    border-radius: 10px;
    color: #fff;
    text-align: center;
}

.blueCountLogoWrp {
    background: linear-gradient(90deg, rgba(2, 0, 36, 1) 0%, rgba(0, 122, 255, 1) 0%, rgba(0, 156, 255, 1) 78%);
}

.pinkCountLogoWrp {
    background: linear-gradient(195deg, rgb(236, 64, 122), rgb(216, 27, 96));
}

.greenCountLogoWrp {
    background: linear-gradient(195deg, rgb(102, 187, 106), rgb(67, 160, 71));
}

.yellowCountLogoWrp {
    background: linear-gradient(195deg, rgb(255, 167, 38), rgb(251, 140, 0));
}

.orangeCountLogoWrp {
    background: linear-gradient(195deg, rgb(239, 83, 80), rgb(229, 57, 53));
}

.countText{
    text-align: end;
    font-size: 28px;
    color: rgba(0, 0, 0, 0.6);
    font-weight: 600;
}

.countDescription{
    color: rgba(0, 0, 0, 0.8);
    text-align: end;
    font-size: 12px;
}