.consoleCard{
    width: 150px;
    height: 150px;
    border-radius: 10px;
    background: #fff;
    box-shadow: rgba(0, 0, 0, 0.1) 0rem 0.25rem 0.375rem -0.0625rem, rgba(0, 0, 0, 0.06) 0rem 0.125rem 0.25rem -0.0625rem;
    overflow: hidden;
    cursor: pointer;
}
.consoleLogo{
    height: 75%;
    width: 100%;
    background-color: #fff;
    padding-top: 10px;
    padding-bottom: 20px;
    padding-left: 10px;
    padding-right: 10px;
}
.consoleName {
    padding-top: 5px;
    height: 25%;
    font-size: 16px;
    width: 100%;
    font-weight: 600;
    letter-spacing: 1px;
    text-align: center;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
}