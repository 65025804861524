.productQtyCountcard {
    width: 100%;
    height: 70px;
    background-color: white;
    border-radius: 10px;
    font-size: 18px;
    font-weight: 600;
    color: rgba(0, 0, 0, 0.5);
    box-shadow: rgba(0, 0, 0, 0.1) 0rem 0.25rem 0.375rem -0.0625rem, rgba(0, 0, 0, 0.06) 0rem 0.125rem 0.25rem -0.0625rem;
    padding: 0px 20px;
}

.productQtyNameProduct {
    width: 30%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-transform: capitalize;

}

.logoContainer {
    height: 60px;
    width: 60px;
    border-radius: 10px;
}

.blueCountLogoWrp {
    background: linear-gradient(90deg, rgba(2, 0, 36, 1) 0%, rgba(0, 122, 255, 1) 0%, rgba(0, 156, 255, 1) 78%);
}

.blackCountLogoWrp {
    background: linear-gradient(195deg, rgb(66, 66, 74), rgb(25, 25, 25));
}

.pinkCountLogoWrp {
    background: linear-gradient(195deg, rgb(236, 64, 122), rgb(216, 27, 96));
}

.greenCountLogoWrp {
    background: linear-gradient(195deg, rgb(102, 187, 106), rgb(67, 160, 71));
}

.yellowCountLogoWrp {
    background: linear-gradient(195deg, rgb(255, 167, 38), rgb(251, 140, 0));
}

.orangeCountLogoWrp {
    background: linear-gradient(195deg, rgb(239, 83, 80), rgb(229, 57, 53));
}

.countTextProduct {
    max-width: 100%;
    text-align: end;
    font-size: 24px;
    color: rgba(0, 0, 0, 0.6);
    font-weight: 600;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.countWwrpProduct {
    width: 70%;
}

.qtyP{
    width: 65%;
}
.priceP{
    width: 35%;
}

.countDescription {
    color: rgba(0, 0, 0, 0.8);
    text-align: end;
    font-size: 12px;
}

.unitDisplay {
    font-size: 14px;
}