.greenStatus {
    width: 100px;
    height: 30px;
    text-align: center;
    border-radius: 10px;
    padding-top: 4px;
    color: green;
    font-weight: 600;
    border: 2px solid green;
}

.orangeStatus {
    width: 100px;
    height: 30px;
    text-align: center;
    border-radius: 10px;
    padding-top: 4px;
    color: orange;
    font-weight: 600;
    border: 2px solid orange;
}

.redStatus {
    width: 100px;
    height: 30px;
    text-align: center;
    border-radius: 10px;
    padding-top: 4px;
    color: red;
    font-weight: 600;
    border: 2px solid red;
}

.sarchText {
    position: relative;
    top: -7px;
}

.addUnitBox{
    padding-right: 20px;
    border-right: solid 1px #000;
}
.productNameDetail{
    font-size: 20px;
    color: rgba(0, 0, 0, 0.6);
        font-weight: 600;
}
.indexDisplay{
    font-size: 34px;
    text-align: center;
    padding-top: 3px;
}

.detailsData{
margin-top: 20px;
}

.headerNameTxt{
    color: rgba(0, 0, 0, 0.6);
        font-weight: 600;
}

.redDeleteBtn{
    background-color: #fff;
    color: red;
}