.loginCard {
    width: 100%;
    border-radius: 10px;
    box-shadow: 0 .25rem .375rem -.0625rem rgba(0, 0, 0, .1), 0 .125rem .25rem -.0625rem rgba(0, 0, 0, .06);
    background-color: #fff;
    padding: 20px 40px;
}

.loginHeader {
    font-style: normal;
    font-weight: 400;
    font-size: 34px;
}

.textFieldWrp {
    margin-top: 50px;
    margin-bottom: 50px;
}

.MuiFormControl-root {
    margin: 0px !important;
}

.loginBtn {
    height: 50px;
    width: 100%;
    background: #d6343a;
    /* background: linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(76,217,100,1) 0%, rgba(23,243,112,1) 81%, rgba(0,255,117,1) 100%); */
    /* background: linear-gradient(90deg, rgba(2, 0, 36, 1) 0%, rgba(0, 122, 255, 1) 0%, rgba(0, 156, 255, 1) 78%); */
    color: white;
    font-size: 22px;
    border-radius: 10px;
}

.loginBtn:hover {
    transform: translateY(-2px);
    box-shadow: 0 3px 9px 0 rgba(0, 0, 0, 0.15);
}


/* .buttonWrapper {
    padding-left: 30px;
    padding-right: 30px;
} */

.forgetPwd {
    margin-top: 25px;
    margin-bottom: 15px;
}

.forgetPwdBtn {
    cursor: pointer;
    text-decoration: underline;
    color: rgba(0, 122, 255, 1);
}

/* .err {
    margin-top: -40px;
} */

@keyframes float {
    0% {
        filter: drop-shadow(0 5px 15px 0px rgba(0, 0, 0, 1));
        transform: translatey(0px);
    }

    50% {
        filter: drop-shadow(0 25px 15px 0px rgba(0, 0, 0, 0.9));
        transform: translatey(-20px);
    }

    100% {
        filter: drop-shadow(0 5px 15px 0px rgba(0, 0, 0, 1));
        transform: translatey(0px);
    }
}

.logoMobile {
    width: 100%;
    height: 100%;
    display: inline-block;
    position: relative;
    filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.5));
    overflow: hidden;
    transform: translatey(0px);
    animation: float 3s ease-in-out infinite;
}