.userTableSubContainer {
    background-color: #fff;
    height: 100%;
    position: relative;
    /* padding: px; */
    border-radius: 0.75rem;
    /* padding-top: 50px; */
    box-shadow: rgba(0, 0, 0, 0.1) 0rem 0.25rem 0.375rem -0.0625rem, rgba(0, 0, 0, 0.06) 0rem 0.125rem 0.25rem -0.0625rem;
}
.supplierListContainer {
    margin-top: 30px;
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 0px;
    padding-bottom: 10px;
}

.tableHeader {
    width: 96%;
    height: 70px;
    border-radius: 10px;
    background: linear-gradient(90deg, rgba(2, 0, 36, 1) 0%, rgba(0, 122, 255, 1) 0%, rgba(0, 156, 255, 1) 78%);
    position: relative;
    top: -25px;
    color: #fff;
    font-weight: 700;
    font-size: 18px;
    padding-top: 22px;
    padding-left: 20px;
    padding-right: 20px;
    z-index: 1000;
}

.tableContainerWrapper {
    width: 100%;
    margin-top: 30px;
}

.userTableContainer {
    margin-top: 100px;
}

.makePaymentHeader{
    color: rgba(0, 0, 0, 0.5);
    /* font-weight: 600; */
}

.makePaymentName{

}