.productCard {
    min-height: 360px;
    height: 360px;
    width: 100%;
    background: #fff;
    border-radius: 10px;
    /* padding: 8px 15px; */
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 8px;
    padding-bottom: 8px;
    box-shadow: rgba(0, 0, 0, 0.1) 0rem 0.25rem 0.375rem -0.0625rem, rgba(0, 0, 0, 0.06) 0rem 0.125rem 0.25rem -0.0625rem;
}

.productCard:hover {
    box-shadow: rgba(0, 0, 0, 0.1) 0rem 0.35rem 0.475rem -0.0725rem, rgba(0, 0, 0, 0.09) 0rem 0.225rem 0.35rem -0.0825rem;
    transform: translateY(-4px);
}

.productName {
    font-size: 18px;
    font-weight: 600;
    letter-spacing: 1px;
    font-family: 'Monaco', monospace;

}

.productNameDiv {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

/* .MuiButtonBase-root{
    padding: 0px !important;
} */
/* .css-78trlr-MuiButtonBase-root-MuiIconButton-root {
    padding: 0px !important;
} */
.iconPedding .MuiIconButton-root{
        padding: 0px !important;
}

.stockFieldHeader {
    font-size: 16px;
    white-space: nowrap;
    overflow: hidden;
    color: gray;
}

.stockFieldWrp {
    width: 100%;
    padding-top: 5px;
    text-align: center;
    height: 40px;
    font-weight: 600;
    letter-spacing: 1px;
    border-radius: 10px;
    /* color: black; */
    /* border: 2px solid rgba(0, 0, 0, 0.1); */
    /* border: 2px solid red; */
}

.blue {
    color: rgba(0, 122, 255, 1);
    border: 2px solid rgba(0, 122, 255, 1);
}

.green {
    color: #228B22;
    border: 2px solid #228B22;
}

.orange {
    color: orange;
    border: 2px solid orange;
}

.redHeader {
    color: #FF6347;
}

.blueHeader {
    color: rgba(0, 122, 255, 1);
}

.greenHeader {
    color: #228B22;
}

.orangeHeader {
    color: orange;
}

.red {
    color: #FF6347;
    border: 2px solid #FF6347;
}

.minStock {
    font-size: 14px;
}

.minQtyDisplay {
    font-weight: 600;
    color: gray;
}

.lastPriceDisplay {
    font-weight: 600;
    color: gray;
}

.stockedField {
    font-weight: 600;
    font-size: 14px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.stockInBtn {
    width: 100%;
    height: 40px;
    background: linear-gradient(90deg, rgba(2, 0, 36, 1) 0%, rgba(0, 122, 255, 1) 0%, rgba(0, 156, 255, 1) 78%);
    border-radius: 10px;
    font-weight: 600;
    font-size: 14px;
    color: #fff;
}

.stockInBtn:hover {
    transform: scale(1.011);
    box-shadow: 0 3px 9px 0 rgba(0, 0, 0, 0.15);
}

.stockOutBtn {
    width: 100%;
    height: 40px;
    font-size: 14px;
    background-image: linear-gradient(195deg, rgb(116, 123, 138), rgb(73, 83, 97));
    /* background: linear-gradient(195deg, #42424a, #191919); */
    border-radius: 10px;
    font-weight: 600;
    color: #fff;
}

.stockOutBtn:hover {
    transform: scale(1.011);
    box-shadow: 0 3px 9px 0 rgba(0, 0, 0, 0.15);
}

.viewDetailBtn {
    width: 100%;
    height: 40px;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.5);
    border: 1px solid rgba(0, 0, 0, 0.5);
    /* background-image: linear-gradient(195deg, rgb(235, 239, 244), rgb(146, 152, 158)); */
    border-radius: 20px;
    font-weight: 600;
    /* color: #fff; */
}

.viewDetailBtn:hover {
    width: 100%;
    height: 40px;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.7);
    border: 1.5px solid rgba(0, 0, 0, 0.7);
    /* background-image: linear-gradient(195deg, rgb(235, 239, 244), rgb(146, 152, 158)); */
    border-radius: 20px;
    font-weight: 600;
    /* color: #fff; */
}