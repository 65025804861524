.supplierDetailContainer {
    min-height: 420px;
    width: 100%;
    word-wrap: break-word;
    background: #fff;
    border-radius: 10px;
    padding: 10px 20px 20px 20px;
    box-shadow: rgba(0, 0, 0, 0.1) 0rem 0.25rem 0.375rem -0.0625rem, rgba(0, 0, 0, 0.06) 0rem 0.125rem 0.25rem -0.0625rem;
    font-size: 14px;
}

.supplierHeader {
    font-size: 18px;
    font-weight: 600;
    color: rgba(0, 0, 0, 0.8);
    position: relative;
    top: -25px;
    left: 10px;
    width: 200px;
    height: 45px;
    border-radius: 10px;
    text-align: center;
    padding-top: 8px;
    color: #fff;
    background: linear-gradient(90deg, rgba(2, 0, 36, 1) 0%, rgba(0, 122, 255, 1) 0%, rgba(0, 156, 255, 1) 78%);
}

.hrLine {
    padding-bottom: 10px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.datePickerWrp {
    background-color: #fff;
    height: 40px;
    position: relative;
    border-radius: 0.75rem;
    box-shadow: rgba(0, 0, 0, 0.1) 0rem 0.25rem 0.375rem -0.0625rem, rgba(0, 0, 0, 0.06) 0rem 0.125rem 0.25rem -0.0625rem;
}

.tabDebit {
    padding-top: 20px;
    font-size: 16px;
    font-weight: 600;
    text-align: center;
    color: red !important;
    border-bottom: 4px solid red !important;
    cursor: pointer;
}

.tabCash {
    padding-top: 20px;
    font-size: 16px;
    font-weight: 600;
    text-align: center;
    color: green !important;
    border-bottom: 4px solid green !important;
    cursor: pointer;
}

.tabTransaction {
    padding-top: 20px;
    font-size: 16px;
    font-weight: 600;
    text-align: center;
    color: #FF6347 !important;
    border-bottom: 4px solid #FF6347 !important;
    cursor: pointer;
}

.products {
    padding-top: 20px;
    font-size: 16px;
    font-weight: 600;
    text-align: center;
    color: cornflowerblue !important;
    border-bottom: 4px solid cornflowerblue !important;
    cursor: pointer;
}

.supplierDetailFeildHeader {
    font-weight: 600;
}