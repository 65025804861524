.productNameHeader{
    white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 60%;
        color: rgba(0, 0, 0, 0.7);
        text-align: left;
        font-weight: bold;
        max-width: 60%;
}

.status{
    width: 40%;
    max-width: 40%;
    text-align: left;
    white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
}

.unitCard{
    width: 100%;
    text-align: center;
    font-size: 18px;
    min-height: 80px;
    color: rgba(0, 0, 0, 0.6);
        font-weight: 600;
    background: #fff;
    border-radius: 10px;
        box-shadow: rgba(195, 146, 146, 0.1) 0rem 0.25rem 0.375rem -0.0625rem, rgba(0, 0, 0, 0.06) 0rem 0.125rem 0.25rem -0.0625rem;
        padding-top: 10px;
        padding-bottom: 10px;
        padding-left: 15px;
        padding-right: 15px;
}
.stockCard {
    width: 100%;
    text-align: center;
    font-size: 18px;
    min-height: 50px;
    color: rgba(0, 0, 0, 0.6);
    font-weight: 600;
    background: #fff;
    border-radius: 10px;
    box-shadow: rgba(195, 146, 146, 0.1) 0rem 0.25rem 0.375rem -0.0625rem, rgba(0, 0, 0, 0.06) 0rem 0.125rem 0.25rem -0.0625rem;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 15px;
    padding-right: 15px;
}

.unitSDis{
    min-width: 40%;
}