.addUserCard {
    padding-left: 30px;
    padding-right: 30px;
    padding-bottom: 30px;
    border-radius: 10px;
    height: 480px;
    width: 100%;
    background-color: #fff;
    box-shadow: rgba(0, 0, 0, 0.1) 0rem 0.25rem 0.375rem -0.0625rem, rgba(0, 0, 0, 0.06) 0rem 0.125rem 0.25rem -0.0625rem;
}

.header {
    width: 250px;
    height: 60px;
    border-radius: 10px;
    /* background: #ff1903; */
    background: linear-gradient(90deg, rgba(2, 0, 36, 1) 0%, rgba(0, 122, 255, 1) 0%, rgba(0, 156, 255, 1) 78%);
    position: relative;
    top: -30px;
    left: 0px;
    z-index: 1000;
}

.addUserTextFieldWrp {
    margin-top: 30px;
    margin-bottom: 45px;
}

.header_text {
    font-size: 22px;
    color: white;
    font-weight: 600;
}

.saveBtn {
    height: 55px;
    width: 100%;
    /* background: linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(76,217,100,1) 0%, rgba(23,243,112,1) 81%, rgba(0,255,117,1) 100%); */
    background: linear-gradient(90deg, rgba(2, 0, 36, 1) 0%, rgba(0, 122, 255, 1) 0%, rgba(0, 156, 255, 1) 78%);
    color: white;
    font-size: 18px;
    border-radius: 10px;
}

.resetBtn {
    height: 55px;
    width: 100%;
    /* background: linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(76,217,100,1) 0%, rgba(23,243,112,1) 81%, rgba(0,255,117,1) 100%); */
    background-image: linear-gradient(195deg, rgb(116, 123, 138), rgb(73, 83, 97));
    color: white;
    font-size: 18px;
    border-radius: 10px;
}